import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { Draggable } from 'react-beautiful-dnd';
import TaskCard from '../TaskCard';

export default function DynamicBoardTask(props) {
  return (
    <Draggable draggableId={`draggable-task-${props.task.id}`} index={props.index}>
      {(provided, snapshot) => (
        <Link role="button" to={`/projects/task/${props.task.id}`} className="mb-2 d-block text-decoration-none text-dark"
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}>
          <Card className={`taskCard mb-2 p-2 border-0 ${snapshot.isDragging ? 'shadow dragging' : 'shadow-sm'}`}>

            <TaskCard task={props.task} />
            
          </Card>
        </Link>
      )}
    </Draggable>
  )
}