import { useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { Card, Col, Dropdown, Form } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Sentiment } from 'assets/js/classes/BootstrapStyles'
import Dashboard from 'assets/js/classes/Dashboard'
import DragDrop from 'assets/js/classes/DragDrop'
import Toast from 'assets/js/classes/Toast'
import FormButton from 'components/forms/FormButton'
import FormControl from 'components/forms/FormControl'
import { StrictModeDroppable } from 'components/ui/StrictModeDroppable'
import DynamicBoardTask from '../components/DynamicBoard/Task'
import { FormControlProvider } from 'contexts/FormControlContext'
import { stateSetScrollSnap, stateUpdateBuckets, useDynamicBoardDispatch, useDynamicBoardState } from 'contexts/DynamicBoardContext'
import { stateUpdateDataset, useSearchBarDispatch, useSearchBarState } from 'contexts/SearchBarContext'
import { updateToasts, useToastsDispatch, useToastsState } from 'contexts/ToastsContext'

export default function AllProjectsColumn({ bucket, index }) {
  // CONTEXT - DYNAMIC BOARD
  const { buckets } = useDynamicBoardState();
  const dynamicBoardDispatch = useDynamicBoardDispatch();
  
  // CONTEXT - SEARCH BAR
  const { dataset, filtered } = useSearchBarState();
  const searchBarDispatch = useSearchBarDispatch();

  // CONTEXT - TOASTS
  const { toasts } = useToastsState();
  const toastsDispatch = useToastsDispatch();


  // STATE PARAMS
  const [editTitle, setEditTitle] = useState(false);

  const handleOnClick = () => {
    if (!filtered) {
      setEditTitle(true);
    } else {
      // THROW TOAST TO USER
      toasts.unshift(new Toast(
        'Error', 
        'Please clear your search filter before trying to rename the list.',
        { autohide: true, sentiment: Sentiment.DANGER },
      ));
      updateToasts(toastsDispatch, { toasts });
    }
  }

  return (
    <Draggable 
      draggableId={`draggable-bucket-${bucket.id}`} 
      index={index}
    >
      {(provided, snapshot) => (
        <Col 
          xs={12} md={6} lg={5} xl={4}
          {...provided.draggableProps}
          ref={provided.innerRef}
        >
          <Card className={`bucketCard border-0 pt-2 my-2 align-self-start ${snapshot.isDragging ? 'shadow' : 'shadow-sm'}`}>
            <span className="d-flex align-items-stretch">

              <div 
                className="p-2 transparentBorder" 
                {...provided.dragHandleProps}
              >
                <FontAwesomeIcon icon={['fas', 'grip-dots-vertical']} fixedWidth />
              </div>
              
              <>
                { editTitle && 
                  <Form 
                    autoComplete="false"
                    className="pr-2 w-100"
                    onSubmit={e => e.preventDefault()}
                  >
                    <FormControlProvider>
                      <FormControl
                        autoFocus={editTitle}
                        className="noBorder border-0 shadow-sm"
                        modelContext={{ 
                          bucket,
                          dashboard: {
                            dataset: buckets, 
                            dispatch: dynamicBoardDispatch,
                            prop: 'buckets',
                            update: stateUpdateBuckets,
                          },
                          searchbar: {
                            dataset,
                            dispatch: searchBarDispatch,
                            prop: 'dataset',
                            update: stateUpdateDataset,
                          },
                        }}
                        name="title"
                        onBlur={() => setEditTitle(false)}  // TODO: change this to submit/save?
                        onKeyDown={Dashboard.handleKeyDown}
                        placeholder="Title..."
                        required
                        value={bucket.title}
                      />
                    </FormControlProvider>
                  </Form>
                }

                { !editTitle && 
                  <div 
                    className="bucketTitle p-2 pl-0 flex-fill" 
                    onClick={handleOnClick}
                  >
                    {bucket.title}
                  </div>
                }

                { !editTitle && 
                  <Dropdown 
                    align="end" 
                    className="d-flex align-items-center"
                  >
                    <Dropdown.Toggle 
                      className="rounded-0 align-self-stretch"
                      variant="link" 
                    >
                      <FontAwesomeIcon icon={['fas', 'ellipsis']} color="#495057" fixedWidth />
                    </Dropdown.Toggle>
                    
                    <Dropdown.Menu>
                      <Dropdown.Item 
                        as={NavLink} 
                        end 
                        to={`/projects/new-task/${bucket.slug}`}
                      >
                        <FontAwesomeIcon icon={['fas', 'rectangle-history-circle-plus']} fixedWidth className="mr-1" />
                        New Task
                      </Dropdown.Item>

                      <Form 
                        autoComplete="false"
                        onSubmit={e => e.preventDefault()}
                      >
                        <FormControlProvider>
                          <FormButton
                            alert={{
                              title: 'Delete this List/Dashboard?',
                              message: bucket?.title,
                              options: {
                                confirmBtnBsStyle: 'danger',
                                type: 'danger',
                              }
                            }}
                            className="dropdown-item rounded-0 text-danger"
                            loadStatus="Deleting..."
                            modelContext={{ 
                              bucket,
                              dashboard: {
                                dataset: buckets, 
                                dispatch: dynamicBoardDispatch,
                                prop: 'buckets',
                                update: stateUpdateBuckets,
                              },
                              searchbar: {
                                dataset,
                                dispatch: searchBarDispatch,
                                prop: 'dataset',
                                update: stateUpdateDataset,
                              },
                            }}
                            onClick={Dashboard.handleDeleteBucket}
                            variant="link"
                          >
                            <FontAwesomeIcon fixedWidth icon={['fas', 'trash']} className="mr-1" />
                            Delete List/Dashboard
                          </FormButton>
                        </FormControlProvider>
                      </Form>
                    </Dropdown.Menu>
                  </Dropdown>
                }
              </>
            </span>

            <StrictModeDroppable 
              droppableId={`droppable-bucket-${bucket.id}`} 
              type={DragDrop.TASKS}
            >
              {(provided, snapshot) => (
                <div 
                  className="bucketDropZone py-2 pl-2 pr-1" 
                  style={snapshot.isDraggingOver ? { background: 'rgba(247, 185, 36, 0.1)' } : {}}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >

                  {bucket.tasks?.map((task, index) => 
                    <DynamicBoardTask key={task.id} task={task} index={index} />
                  )}
      
                  {provided.placeholder}
                </div>
              )}
            </StrictModeDroppable>
          </Card>
        </Col>
      )}
    </Draggable>
  )
}