import React from 'react';

const SearchBarStateContext = React.createContext();
const SearchBarDispatchContext = React.createContext();

function reducer(state, action) {
  return { ...state, ...action };
}

function useSearchBarState() {
  const context = React.useContext(SearchBarStateContext);
  if(!context) throw new Error('useSearchBarState must be used in SearchBarProvider');
  return context;
}

function useSearchBarDispatch() {
  const context = React.useContext(SearchBarDispatchContext);
  if(!context) throw new Error('useSearchBarDispatch must be used in SearchBarProvider');
  return context;
}

function SearchBarProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, { dataset: [], filtered: false });

  return (
    <SearchBarStateContext.Provider value={state}>
      <SearchBarDispatchContext.Provider value={dispatch}>
        {props.children}
      </SearchBarDispatchContext.Provider>
    </SearchBarStateContext.Provider>
  )
}

function stateUpdateSearchBar(dispatch, data) {
  dispatch({...data})
}

function stateUpdateDataset(dispatch, { dataset }) {
  dispatch({ dataset });
}

export { SearchBarProvider, stateUpdateDataset, stateUpdateSearchBar, useSearchBarDispatch, useSearchBarState };